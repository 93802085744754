import React, { PureComponent } from 'react';
import { Form, Input } from 'antd';
import AgentForm from '../components/YchAgentForm';
import md5 from '@/utils/md5';
import Layout from '@/layout/Layout';
import { FormInstance } from 'antd/lib/form';
import apiYch from '@/api/api-ych2';
import { Dialog } from '@/components';
import { YchApi } from '@/web/api';

export default class AgentEdit extends PureComponent {
  private initialValues: any = JSON.parse(sessionStorage.getItem('$agent_edit_info') || '{}')

  private form: AgentForm | null = null
  private passwordForm: FormInstance | null = null
  state = {
    loading: false,
    visible: false,
  };
  render() {
    const { loading, visible } = this.state;
    return (
      <Layout.FooterBar onOk={this.clickSave} loading={loading}>
        <AgentForm ref={r => this.form = r} initialValues={this.initialValues} mode="edit" onReset={() => {
          this.setState({
            visible: true
          });
        }}/>

        <Dialog full={false} title="重置密码" visible={visible}
          onCancel={() => this.setState({visible: false})}
          onOk={this.clickRestPassword}>
          <Form labelCol={{span: 5}} style={{width: 400}} ref={r => this.passwordForm = r}>
            <Form.Item name="password" label="新密码" rules={[
              {
                required: true,
                message: '请填写新密码'
              },
              {
                min: 6,
                message: '密码最少需6位'
              },
            ]}>
              <Input.Password placeholder="填写" maxLength={25}/>
            </Form.Item>
            <Form.Item name="confirmPassword" label="确认密码" rules={[
                {
                  required: true,
                  message: '请确认密码!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('两次密码填写不一致!'));
                  },
                }),
              ]}>
              <Input.Password placeholder="填写"/>
            </Form.Item>
          </Form>
        </Dialog>
      </Layout.FooterBar>
    )
  }

  clickSave = async () => {
    let values;
    try {
      values = await this.form?.validateFields();
    } catch (e) {
      console.log(e);
      return;
    }

    return YchApi.agent_update({
      ...values,
      agentId: this.initialValues.agentId,
    })
    .then(() => {
      showSuccess.save();
      sessionStorage.removeItem('$agent_edit_info');
    })
    .finally(() => {
      this.setState({
        loading: false,
      });
    })
  }

  clickRestPassword = async () => {

    let values;
    try {
      values = await this.passwordForm?.validateFields();

      this.setState({
        loading: true,
      });
      return apiYch['agent.resetPassword'].post({
        agentId: this.initialValues.id,
        password: md5.hex_md5(values.password),
      })
      .then(() => {
        showSuccess.save('保存成功');
        this.setState({
          visible: false,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });

    } catch(e) {
      console.error(e);
    }
  }
}
